@import '@/styles/variables';
@import '@/styles/breakpoints';

/* Input Scss */

.input {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  gap: 0.5rem;

  & input {
    width: 100%;
    background-color: $black-700;
    border-radius: 0.5rem;
    border: 1px solid $black-600;
    padding: 0.45rem 1rem;
    color: $black-50;
    font-size: 1rem;
    line-height: 1rem;
    font-weight: 300;
    letter-spacing: -4%;
    font-family: 'Inter', sans-serif;

    &[type='submit'] {
      cursor: pointer;
      transition: all 0.15s ease-in-out;

      &:hover {
        filter: brightness(1.4);
      }
    }

    @include responsive('lg') {
      font-size: 1.1rem;
      line-height: 1.1rem;
    }

    &::placeholder {
      color: $black-400;
    }
  }
}

/* Finished Input Scss */

/* AuthInput Scss */

.authInput {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;

  input {
    padding: 0.75rem 1rem 0.75rem 2.75rem;

    @include responsive('xxs') {
      padding: 0.75rem 1.5rem 0.75rem 2.5rem;
    }

    @include responsive('sm') {
      padding: 0.75rem 1.5rem 0.75rem 3.7rem;
    }
  }

  .icon {
    display: flex;
    font-size: 1.3rem;
    position: absolute;
    left: 0.8rem;
    z-index: 1;
    top: 50%;
    transform: translate(0, -50%);
    user-select: none;
    pointer-events: none;
    width: 20px;
    height: auto;

    @include responsive('sm') {
      left: 1.2rem;
      width: 24px;
    }

    @include responsive('lg') {
      font-size: 1.5rem;
    }
  }
}

/* Finished AuthInput Scss */

.side {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.up {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
