@import '@/styles/variables';
@import '@/styles/breakpoints';

.editProfileWrapper {
  height: max-content;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 100%;
  padding: 1rem;

  @include h-responsive('md') {
    margin: 6rem auto;
  }

  @include responsive('md') {
    margin: 5rem 0 0;
  }

  .editProfileText {
    font-size: x-large;
    font-weight: bold;
  }

  .seperateLine {
    width: 100%;
    height: 1px;
    margin: 1rem 0 0.5rem;
    border-color: $black-shadow-20;
  }

  .editProfileBox {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;

    @include responsive('sm') {
      grid-template-columns: 2fr 1fr;
    }

    .editProfileBoxForm {
      display: flex;
      flex-direction: column;
      gap: 1.5rem;
      order: 2;

      @include responsive('sm') {
        order: 1;
      }

      .actionButtons {
        display: flex;
        width: max-content;
        gap: 1rem;

        input {
          padding: 0.65rem 1.25rem;
        }

        div:nth-child(1) input {
          background-color: $brown-700;
        }

        div:nth-child(2) input {
          color: $red-200;
        }
      }
    }

    .avatar {
      display: flex;
      height: max-content;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      gap: 1rem;
      order: 1;
      position: relative;

      @include responsive('md') {
        max-width: 10.5rem;
        justify-content: flex-start;
        order: 2;
      }

      div {
        position: relative;
        width: 100%;
        max-width: 10.5rem;
        height: auto;
        aspect-ratio: 1/1;

        img {
          border-radius: 50%;
          border: 1px solid $gray-shadow-20;
          padding: 0.5rem;
        }
      }

      button {
        position: absolute;
        bottom: -0.25rem;
        right: -2rem;
        padding: 0.75rem 1rem;
        border-radius: 0.25rem;
        background-color: $black-shadow-60;
        color: $white-500;
        display: inline-flex;
        transition: all 0.2s ease-in-out;

        &:hover {
          background-color: $black-shadow-40;
          color: $white-100;
        }

        &::before {
          content: url('/images/edit-icon.svg');
          display: inline-block;
          margin-right: 0.25rem;
        }
      }
    }
  }
}
